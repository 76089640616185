<template>
  <div class="page-content">
    <page-breadcrumb title="Goga Rank Topic" />
    <section class="py-2">
      <b-row>
        <b-col md="10">
          <h2 class="mb-2">Goga Rank Topic Management</h2>
        </b-col>
      </b-row>
    </section>
    <!-- <div class="d-flex" style="width: 200px">
      <strong class="h6">Go to stage</strong>
      <b-input v-model="currentStageToGo" @input="goToStage" />
    </div> -->
    <section>
      <div class="text-center">
        <topic-create-form />
      </div>
    </section>
    <section class="mt-3 d-flex">
      <b-row class="w-100">
        <b-col
          v-for="(item, index) in topics"
          :key="index"
          class="col-lg-2 col-md-2 col-12"
        >
          <b-link
            :to="{
              name: 'goga-rank-topic-detail',
              params: { id: item._id },
            }"
            :id="item._id"
          >
            <b-card class="text-center">
              <div>
                {{ item.title }}
              </div>
            </b-card>
          </b-link>
        </b-col>
      </b-row>
    </section>
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>

<script>
import service from "../service";
import TopicCreateForm from './_components/TopicCreateForm.vue'
export default {
  components: {
    TopicCreateForm
  },
  data() {
    return {
      current_page: 1,
      total_items: 0,
      items_perpage: 50,
      topics: null,
      currentStageToGo: null,
    };
  },
  watch: {
    current_page() {
      this.getAll();
    },
  },
  created() {
    this.getAll();
  },
  methods: {
    async getAll() {
      let response = await service.getList({
        page: this.current_page,
        limit: this.items_perpage,
      });
      if(response){
        this.topics = response.list;
        this.total_items = response.total
      }
    },
    goToStage(){
      document.getElementById('stage_'+this.currentStageToGo).scrollIntoView({
        behavior: 'smooth'
      });
    }
  },
};
</script>
<style scoped>
.badge-secondary {
  background-color: #f8f8f8;
}
</style>
